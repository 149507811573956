// extracted by mini-css-extract-plugin
export var addBC = "cardsLsaTemplate-module--addBC--5rz8I";
export var address = "cardsLsaTemplate-module--address--CI+w2";
export var cardsCont = "cardsLsaTemplate-module--cardsCont---ph6C";
export var contactButton = "cardsLsaTemplate-module--contactButton--FToOP";
export var dataCont = "cardsLsaTemplate-module--dataCont--dn6S7";
export var emailBC = "cardsLsaTemplate-module--emailBC--Z74H4";
export var iconBC = "cardsLsaTemplate-module--iconBC--OuHos";
export var iconsCont = "cardsLsaTemplate-module--iconsCont--w0QYH";
export var infoCont = "cardsLsaTemplate-module--infoCont--hgZd-";
export var infoPersonal = "cardsLsaTemplate-module--infoPersonal---Nd1N";
export var instagramBC = "cardsLsaTemplate-module--instagramBC---cly5";
export var interactiveAssets = "cardsLsaTemplate-module--interactiveAssets--T5wTt";
export var linkedinBC = "cardsLsaTemplate-module--linkedinBC--7IU8e";
export var mapsBC = "cardsLsaTemplate-module--mapsBC--LTiNc";
export var nameBC = "cardsLsaTemplate-module--nameBC--V-ZWH";
export var profilePic = "cardsLsaTemplate-module--profilePic--97RXe";
export var round = "cardsLsaTemplate-module--round--eA4Ri";
export var textItem = "cardsLsaTemplate-module--textItem--aHO2H";
export var webBC = "cardsLsaTemplate-module--webBC--2NxJf";
export var whatsappBC = "cardsLsaTemplate-module--whatsappBC--EBxsD";